<template>
  <div class="container-fluid">
    <div class="bg"></div>
    <div class="row L1">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 text-center">
            <h1>RAW 2 DNG</h1>
            <p>试试看，只要简单转换一下格式，就能大幅改善你的后期工作效率</p>
            <div class="btnbox hidden-xs">
              <a class="btn" href="https://pan.baidu.com/s/1UyhoeOxGIKG0aMXTpXptng&pwd=bkb8" target="_blank">立即下载</a>
              <p class="group">
                <label for="">使用前请下载并安装Adobe DNG Converter：</label>
                <a href="javascript:void(0);" @click="goto(`https://helpx.adobe.com/camera-raw/using/adobe-dng-converter.html`)">官方下载地址</a>
              </p>
            </div>
            <div class="btnbox visible-xs">
              <a class="btn" href="javascript:void(0);">请前往桌面端下载</a>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="screenshot">
              <img  draggable="false" class="img-responsive" src="@/assets/img/raw2dng/1.gif" alt="" srcset="" >
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row L2">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <div class="page-header">
              <h1>为什么要转码成DNG？</h1>
            </div>
            <h3>对各种平台、各种修图软件的良好兼容性<span>即使是5年前的Ps也能正常打开dng，安卓ios也能完美支持，不用担心兼容性问题</span></h3>
            <h3>无须安装插件就可以直接在文件夹中查看缩略图<span>预览图片再也不需要按空格、不需要Bridge就能看图</span></h3>
            <h3>无损压缩，不丢失任何数据<span>DNG也是RAW，同样具备法律效力</span></h3>
            <h3>转码后可大幅减小文件容量<span>索尼a7r3的RAW每张照片80MB，压缩后变成40MB，完全不损失画质</span></h3>
            <h3>良好的兼容性，加速后期处理<span>在后期处理时能提速30%</span></h3>
            <h3>支持转码佳能EOS R5、R5C、R5 mark II的CRM视频RAW文件<span>方便使用LR、达芬奇调色</span></h3>

            <div class="page-header text-center">
              <h1>RAW 2 DNG是如何改善你的工作流的？</h1>
              <h4 class="tips">很简单，只要将你<b>【拍摄完将照片从存储卡拷贝到硬盘中】</b>的习惯更改为<b>【使用本工具从相机存储卡直接转码dng到电脑中】</b>即可。</h4>
              <div class="r">
                <div class="box"><h3>不会浪费你的时间</h3>因为这并不是将文件先从卡里复制出来再转码（这样做有6个步骤：读取、写入、读取、转码、写入），而是只有读取、转码、写入3个步骤。你需要做的仅仅是打开软件，简单设置一下，点击开始即可。</div>
                <div class="box"><h3>不会浪费你的硬盘空间</h3>与其在硬盘快满了的时候，再想到来转码减小空间，不如在每次导入照片时就把DNG提前转码好。</div>
                <div class="box"><h3>不会让你有负担</h3>转成DNG之后所有数据都是保留的，你可以正常读取拍摄信息，不会损失画质。即使被盗图了，一样也能拿来作为维权证据，因为里面保留了所有版权信息、拍摄时间和其他拍摄信息。</div>
              </div>
              
            </div>
            <div class="page-header text-center">
              <h1>配置项范例</h1>
            </div>
            <div class="panel">
              <div class="panel-inbox">
                <img style="width: 120px;margin: 0 auto;" draggable="false" class="img-responsive" src="@/assets/img/raw2dng/icon512.png" alt="" srcset="">
                <h4 class="text-center">将照片导入电脑的同时进行转码（推荐）</h4>
                <p>1、通过读卡器将照片拷贝进电脑→再转码→最后删除，这样就太费时间了，本工具可以直接读取存储卡，将其转码成dng存进电脑中并自动删除源文件</p>
                <p>2、启动本工具，按照下图进行设置</p>
                <img  draggable="false" class="img-responsive" src="@/assets/img/raw2dng/4.jpg" alt="" srcset="" >
                <p>3、点击下方蓝色按钮开始转码。此时软件将会读取存储卡中的所有照片，将其转码成dng并存储到目标文件夹，每张照片转码完成后都会自动删除。这样既节约时间，又节省硬盘。</p>
              </div>
            </div>
            <div class="panel">
              <div class="panel-inbox">
                <img style="width: 120px;margin: 0 auto;" draggable="false" class="img-responsive" src="@/assets/img/raw2dng/icon512.png" alt="" srcset="">
                <h4 class="text-center">正在通过网络传输照片，在传输同时自动转码成DNG</h4>
                <p>1、照片文件正在传输中，电脑不能直接获取到源文件夹并对其中的照片进行转码。不过不用担心，不必等待照片全部传输完毕，我们可以在传输的同时进行转码。</p>
                <p>2、启动本工具，按照下图进行设置</p>
                <img  draggable="false" class="img-responsive" src="@/assets/img/raw2dng/5.jpg" alt="" srcset="" >
                <p>3、点击下方蓝色按钮开始转码。此时软件将会监控所选择的素材文件夹，当一个照片文件传输完成时，软件将会自动识别到并转码成dng，转码完成后删除该文件。这样就可以做到“文件一边传输一边转码”的效果，文件传输完成的同时，就已经准备好dng了。</p>
              </div>
            </div>
            <div class="panel">
              <div class="panel-inbox">
                <img style="width: 120px;margin: 0 auto;" draggable="false" class="img-responsive" src="@/assets/img/raw2dng/icon512.png" alt="" srcset="">
                <h4 class="text-center">硬盘中有大量的raw文件，释放硬盘空间</h4>
                <p>1、本工具支持自动查找子文件夹中的raw文件，利用这个特性，就可以无脑挂机转码</p>
                <p>2、启动本工具，按照下图进行设置</p>
                <img  draggable="false" class="img-responsive" src="@/assets/img/raw2dng/6.jpg" alt="" srcset="" >
                <p>3、点击下方蓝色按钮开始转码。由于文件数量巨大，可能会在开始时有些许卡顿，不过不必担心，工具会在完整读取硬盘中的文件后依次进行转码，高强度的读取和写入将会持续一段时间，期间请确保硬盘不要强制断开、并且放置在散热良好的地方。</p>
              </div>
            </div>
            
            <div class="page-header">
              <h1>配置项说明</h1>
            </div>
            <div class="intro"><label for="">素材文件夹</label>本工具将读取这个文件夹中的raw文件进行转码。默认将只转码该文件夹中的raw文件，如勾选“包含子文件夹”，则会查找该文件夹及所有下级文件夹</div>
            <div class="intro"><label for="">输出文件夹</label>本工具将转码完成的dng文件存储到这个文件夹中。可以选择保存到素材所在的相同文件夹、素材文件夹下的子文件夹“DNG”、或是指定一个文件夹。请不要选择一个网络位置，或是写入速度很差的磁盘，会容易因为写入慢、写入中断等其他情况导致转码的失败。</div>
            <div class="intro"><label for="">转码时</label>选择转码时的操作。有时候输出文件夹中可能会存在同名的dng文件，这可能是上次转码过后的结果，您可以选择不重命名、无提示覆盖或是直接跳过当前raw不转码。</div>
            <div class="intro"><label for="">转码完成</label>这里需要选择每张照片转码完成时的操作。通常我们会选择删除并跳过回收站，这样既可以节省时间也可以节省硬盘（如果不跳过回收站，那么该文件还是会占用硬盘空间）。如果您选择不删除文件，那么转码完成的raw文件将会被移动入素材文件夹中的“Finished”文件夹，本工具会自动忽略该文件夹中的照片。</div>
            
            <div class="page-header">
              <h1>软件说明</h1>
            </div>
            <p>系统支持Windows 10+ 和 macOS 10.0.0+</p>
            <p>运行需要Adobe DNG Converter，请点击 <a href="https://helpx.adobe.com/camera-raw/using/adobe-dng-converter.html">此链接</a> 前往官网下载，或是点击网页上方的下载链接</p>

            <div class="page-header">
              <h1>更新日志</h1>
            </div>
            <p>20240903 1.8.3 增加对佳能EOS R5C、R5 mark II的crm转码支持</p>
            <p>20240717 1.8.1 修复在转码crm时无提示的问题</p>
            <p>20240712 1.8.0 增加对佳能R5的8K RAW视频的支持</p>
            <p>20231216 1.7.2 自定义的输出目录支持创建多层文件夹</p>
            <p>20231017 1.7.1 修复一个导致素材意外丢失的bug</p>
            <p>20230829 1.7.0 支持嵌入全尺寸预览；windows增加右键菜单</p>
            <p>20220610 1.6.2 修复选择“跳过转码”时卡死的问题</p>
            <p>20211230 1.6.0 修复选择“转码至自定义文件夹”时无法启动转码的问题；支持多核心CPU，优化进程调度，提升性能</p>
            <p>20210525 1.5.0 支持自动查找子文件夹转码；增加删除跳过回收站功能</p>
            <p>20210421 1.4.0 显示转码前和转码后文件容量显示；修复同一个文件夹无法重复启动转码的问题</p>
            <p>20210330 1.1.0 修复问题，精简界面</p>
            <p>20210329 1.0.0 第一版</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RAW2DNG',
  metaInfo: {
    title: 'DNG自动转码工具', // set a title
    meta: [{             // set meta
      name: 'keywords',
      content: 'DNG,RAW,图片,照片,转码,转换,转格式,预览,缩略图,后期,Photoshop,Camera RAW,ACR,LR,Lightroom,修图,工作流,批量,自动'
    },
    {
      name: 'description',
      content: '快速将RAW照片转换为DNG格式，支持批量'
    }],
    link: []
  },
  props: {
    
  },
  computed:{

  },
  data(){
    return {
      publicPath: process.env.BASE_URL
    }
  },
  mounted(){
    
  },
  methods:{
    download(link){
      var a = document.createElement('a');
      a.href = link;//下载文件地址
      a.click(); 
    },
    goto(url){
      window.open(url,"_blank")
    }
  }

}
</script>
<style lang="less" scoped>
	.flex{
		display: flex;
		align-items: center;
		justify-content: flex-start;	
	}
  .container-fluid{
    width: 100%;
    padding: 0;
    margin: 0;
    .bg{
      width: 100%;
      height: 800px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      background-image: linear-gradient(25deg, #253c4c, #375c59, #477f65, #56a371);
    }
    .row{
      margin: 0;
      padding: 0;
    }
    .L1{
      color: #fff;
      padding-top: 100px;
      height: 1200px;
      
      h1{
        font-size: 64px;
        font-weight: 300;
        margin-top: 70px;
        @media (max-width: 970px) { 
          margin: 100px 0 50px;
        }
      }
      p{
        font-weight: 300;
      }
      .btnbox{
        text-align: center;
        margin: 60px 0;
        .btn{
          font-size: 16px;
          line-height: 1;
          padding: 8px 20px 8px;
          border: 1px solid #fff;
          color: #fff;
          border-radius: 20px;
          margin: 10px 0;
          display: inline-block;
          margin-right: 10px;
          &:hover{
            color: #56a371;
            background-color: #fff;
          }
        }
        a{
          color: #fff;
        }
        .group{
          label{
            font-weight: 300;
          }
          a{
            margin: 0 10px;
          }
        }
        &.visible-xs{
          .btn{
            border: none;
            box-shadow: none;
            &:hover{
              background: transparent;
              color: #fff;
            }
          }
        }
      }
      
      .screenshot{
        width: 100%;
        overflow: hidden;
        text-align: center;
        padding-bottom: 50px;
        img{
          width: 80%;
          border-radius: 10px;
          display: inline-block;
          box-shadow: 0 0 30px rgba(0,0,0,0.2);
        }
      }
    }
    .L2{
      .page-header{
        margin-top: 90px;
        .tips{
          color: #666;
          margin: 20px 0;
          font-size: 14px;
        }
        .r{
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          grid-row-gap: 15px;
          grid-column-gap: 15px;
          .box{
            flex-grow: 1;
            margin: 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            box-shadow: 0 0 10px rgba(0,0,0,0.2);
            border-radius: 10px;
            padding: 20px 30px 30px;
            text-align: left;
            border: 1px solid #276b5b;
            color: #fff;
            cursor: default;
            background-image: linear-gradient(25deg,  #19424b, #276b5b, #30966a);
            h3{
              margin: 0 0 13px 0;
              color: #fff;
              font-weight: 400;
            }
            &:hover{
              transform: scale(1.02);
            }
          }
        }
      }
      .intro{
        margin-bottom: 15px;
        .flex;
        align-items: flex-start;
        label{
          margin-right: 10px;
          flex-shrink: 0;
          width: 100px;
        }
      }
      h3{
        span{
          display: block;
          font-size: 16px;
          font-weight: normal;
          color: #999;
          margin-top: 5px;
        }
      }
      .workflow{
        max-width: 450px;
      }
      .panel{
        border: 1px solid #ccc;
        color: #333;
        opacity: 0.9;
        border-radius: 10px;
        &:hover{
          opacity: 1;
        }
        .panel-inbox{
          padding: 20px 50px 40px;
          background-color: rgba(255,255,255,0.1);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
        }
        h4{
          margin: 0;
          font-weight: bold;
          margin-bottom: 20px;
          font-size: 32px;
        }
        img{
          display: inline-block;
        }
      }
    }
    @media screen and (max-width:900px){
      .L1{
        height: 800px;
      }
      .L2{
        .panel{
          .panel-inbox{
            padding: 10px 20px;
          }
          h4{
            font-size: 28px;
          }
        }
      }
    }
  }
  
</style>